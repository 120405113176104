import Image from 'next/image'

import HubHomeCard from 'components/HubHomeCard'
import Text from 'components/typography/Text'
import prismaExamplesIcon from 'public/img/icon-prisma-examples.svg'
import prismaGuidesIcon from 'public/img/icon-prisma-guides.svg'

function Home() {
  return (
    <div className="
      self-stretch
      flex-1
      flex
      flex-col
      items-center
      justify-center
      bg-background-dots
      bg-cover
    ">
      <div className="
        relative
        bottom-[68px]
        self-stretch
        flex
        flex-col
        items-center
        justify-center
        space-y-12
      ">
        <Text
          className="text-4xl lg:text-5xl"
          size={null}
          text="Prisma Playground"
          typeface={Text.typeface.BarlowBold}
        />
        <div className="
          flex
          flex-col
          items-center
          justify-center
          space-y-6
          lg:flex-row
          lg:space-y-0
          lg:space-x-6
        ">
          <HubHomeCard
            icon={<Image alt="Prisma examples icon" src={prismaExamplesIcon} priority/>}
            pathname="/examples"
            text={(
              <p>
                <Text
                  color="text-gray-600"
                  size={Text.size.lg}
                  text="Explore how to "
                />
                <Text
                  color="text-gray-600"
                  size={Text.size.lg}
                  text="send database queries "
                  typeface={Text.typeface.InterBold}
                />
                <Text
                  color="text-gray-600"
                  size={Text.size.lg}
                  text="in an interactive sandbox environment."
                />
              </p>

            )}
            titleBottomLine="Prisma Client"
          />
          <HubHomeCard
            icon={<Image alt="Prisma guides icon" src={prismaGuidesIcon} priority/>}
            pathname="/guides"
            text={(
              <p>
                <Text
                  color="text-gray-600"
                  size={Text.size.lg}
                  text="Learn how to "
                />
                <Text
                  color="text-gray-600"
                  size={Text.size.lg}
                  text="evolve your database schema "
                  typeface={Text.typeface.InterBold}
                />
                <Text
                  color="text-gray-600"
                  size={Text.size.lg}
                  text="with interactive guides."
                />
              </p>
            )}
            titleBottomLine="Prisma Migrate"
          />
        </div>
      </div>

    </div>
  )
}

export default Home
