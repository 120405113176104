import { ReactNode } from 'react'
import Link from 'next/link'

import Text from 'components/typography/Text'

export interface Props {
  icon: ReactNode
  pathname: string
  titleTopLine?: string
  titleBottomLine?: string
  text: ReactNode
}

function HubHomeCard({
  icon,
  pathname,
  titleTopLine,
  titleBottomLine,
  text,
}: Props) {
  return (
    <Link
      href={{ pathname: pathname }}
      passHref
    >
      <a className="h-full flex">
        <div className="
          flex
          flex-col
          space-y-4
          transition-all
          p-6
          max-w-[330px]
          rounded-lg
          cursor-pointer
          shadow-home-card
          border-2
          border-indigo-100
          hover:border-indigo-600
          hover:shadow-home-card-hover
          bg-white
        ">
          <div className="
            flex
            items-center
            justify-start
            space-x-4
          ">
            {icon}
            <div className="
              flex
              flex-col
              items-start
              justify-start
            ">
              <Text
                className="leading-[26.4px]"
                size={Text.size['2xl']}
                text={titleTopLine}
                typeface={Text.typeface.BarlowBold}
              />
              <Text
                className="leading-[26.4px]"
                size={Text.size['2xl']}
                text={titleBottomLine}
                typeface={Text.typeface.BarlowBold}
              />
            </div>
          </div>
          {text}
        </div>
      </a>
    </Link>
  )
}

export default HubHomeCard
